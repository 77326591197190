html,
body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: $fontM;
	color: var(--white);
	background-color: var(--bg-color);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
	font-size: 62.5%;
	line-height: 1.5;
	scroll-behavior: smooth;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

.visuallyhidden {
	position: absolute;
	width: 1px !important;
	height: 1px !important;
	margin: -1px;
	border: 0;
	padding: 0;
	white-space: nowrap;
	clip-path: inset(100%);
	clip: rect(0 0 0 0);
	overflow: hidden;
}

.hidden {
	opacity: 0;
	visibility: hidden;
}

#root {
	min-height: 100vh;
	display: flex;
}

.wrapper {
	display: flex;
	flex-grow: 1;
	overflow-x: hidden;
}

.content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: hidden;
}

.main {
	flex-grow: 1;
	display: flex;

	&__wrap {
		padding-top: 15.3rem;
	}
}

.container {
	width: 100%;
	margin: 0 auto;
	padding: 0 3rem;
	max-width: 140rem;
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	@include --sm {
		padding: 0 2rem;
	}
}

#techSupport{
	text-align: center;
	font-size: 15px;
	color: grey;
	margin-top: 10px;
	text-transform: uppercase;
}