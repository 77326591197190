.form {
    &__body {
        flex-grow: 1;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &__row {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2.4rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__block {
        display: block;
        width: 100%;
        margin-bottom: 1.6rem;
        max-width: 36rem;
        z-index: 1;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__rowRef {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 1.6rem;
        max-width: 36rem;
        width: 100%;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__input {
        flex: 5;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-family: $fontM;
        font-size: 1.4rem;
        font-weight: 300;
        font-style: normal;
        line-height: normal;
        display: block;
        width: 100%;
        padding: 1.75rem 2rem;
        border: 0.1rem solid transparent;
        color: var(--white);
        background-color: rgba(var(--input-bg-rgb), 0.5);
        border-radius: 0.8rem;
        transition: 0.2s;
        margin: 0;
        box-shadow: 0 0.5rem 1.3rem 0 rgba(var(--black-rgb), 0.5);

        &_textarea {
            resize: none;
        }

        &::-webkit-input-placeholder {
            color: rgba($color: var(--white-rgb), $alpha: 0.5);
        }
        &::-moz-placeholder {
            color: rgba($color: var(--white-rgb), $alpha: 0.5);
        }
        &:-ms-input-placeholder {
            color: rgba($color: var(--white-rgb), $alpha: 0.5);
        }
        &::placeholder {
            color: rgba($color: var(--white-rgb), $alpha: 0.5);
        }

        &:focus {
            // border-color: rgba($color: var(--primary-rgb), $alpha: 0.4);
        }

        @include --sm {
            padding: 1.5rem;
        }
    }
}

.search-wrap {
    position: relative;
    max-width: 37.6rem;

    & [class*='icon-'] {
        position: absolute;
        color: var(--light);
        font-size: 1.6rem;
        left: 1.6rem;
        top: 50%;
        transform: translateY(-50%);
    }

    & .input {
        padding-left: 4.8rem;
        z-index: 1;
    }
}

.checkbox {
    text-align: left;
    font-family: $fontM;
    font-size: 1.4rem;
    font-weight: 300;
    font-style: normal;
    line-height: normal;
    color: var(--white);

    & input:checked ~ .checkbox__content:before {
        color: var(--white);
    }

    &__content {
        position: relative;
        padding-left: 4.2rem;
        display: inline-block;

        &:before {
            content: '\e81a';
            font-family: 'Fontello';
            color: transparent;
            width: 2.4rem;
            height: 2.4rem;
            line-height: 2.4rem;
            border-radius: 0.3rem;
            position: absolute;
            left: 0;
            text-align: center;
            font-size: 1rem;
            border: 0.1rem solid #979797;
        }
    }

    &__text {
        & a {
            color: var(--yellow);
            transition: 0.2s;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
