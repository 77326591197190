@mixin --xxl {
    // max-width: 1679px
    @media screen and (max-width: $--xxl) {
        @content;
    }
}

@mixin --xl {
    // max-width: 1439px
    @media screen and (max-width: $--xl) {
        @content;
    }
}

@mixin --lg {
    // max-width: 1279px
    @media screen and (max-width: $--lg) {
        @content;
    }
}

@mixin --md {
    // max-width: 1023px
    @media screen and (max-width: $--md) {
        @content;
    }
}
@mixin --md-up {
    // max-width: 1023px
    @media screen and (min-width: $--sm) {
        @content;
    }
}

@mixin --sm {
    // max-width: 767px
    @media screen and (max-width: $--sm) {
        @content;
    }
}

@mixin --xs {
    // max-width: 479px
    @media screen and (max-width: $--xs) {
        @content;
    }
}
