@font-face {
	font-family: 'Druk Text Cyr';
	src: url('../../fonts/DrukCyr/DrukTextCyr-Bold.woff2') format('woff2'),
		url('../../fonts/DrukCyr/DrukTextCyr-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Druk Cyr';
	src: url('../../fonts/DrukCyr/DrukCyr-Heavy.woff2') format('woff2'),
		url('../../fonts/DrukCyr/DrukCyr-Heavy.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Druk Wide Cyr';
	src: url('../../fonts/DrukCyr/DrukWideCyr-Bold.woff2') format('woff2'),
		url('../../fonts/DrukCyr/DrukWideCyr-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Druk Text Cyr';
	src: url('../../fonts/DrukCyr/DrukTextCyr-Super.woff2') format('woff2'),
		url('../../fonts/DrukCyr/DrukTextCyr-Super.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Druk Wide Cyr';
	src: url('../../fonts/DrukCyr/DrukWideCyr-Medium.woff2') format('woff2'),
		url('../../fonts/DrukCyr/DrukWideCyr-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Druk Cyr';
	src: url('../../fonts/DrukCyr/DrukCyr-Bold.woff2') format('woff2'),
		url('../../fonts/DrukCyr/DrukCyr-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Druk Text Cyr';
	src: url('../../fonts/DrukCyr/DrukTextCyr-Heavy.woff2') format('woff2'),
		url('../../fonts/DrukCyr/DrukTextCyr-Heavy.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Druk Text Cyr';
	src: url('../../fonts/DrukCyr/DrukTextCyr-Medium.woff2') format('woff2'),
		url('../../fonts/DrukCyr/DrukTextCyr-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Druk Cyr';
	src: url('../../fonts/DrukCyr/DrukCyr-Super.woff2') format('woff2'),
		url('../../fonts/DrukCyr/DrukCyr-Super.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Druk Wide Cyr';
	src: url('../../fonts/DrukCyr/DrukWideCyr-Heavy.woff2') format('woff2'),
		url('../../fonts/DrukCyr/DrukWideCyr-Heavy.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Druk Cyr';
	src: url('../../fonts/DrukCyr/DrukCyr-Medium.woff2') format('woff2'),
		url('../../fonts/DrukCyr/DrukCyr-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Druk Wide Cyr';
	src: url('../../fonts/DrukCyr/DrukWideCyr-Super.woff2') format('woff2'),
		url('../../fonts/DrukCyr/DrukWideCyr-Super.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'),
		url('../../fonts/Montserrat/Montserrat-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat/Montserrat-ExtraLight.woff2') format('woff2'),
		url('../../fonts/Montserrat/Montserrat-ExtraLight.woff') format('woff');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat/Montserrat-Thin.woff2') format('woff2'),
		url('../../fonts/Montserrat/Montserrat-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat/Montserrat-ExtraBold.woff2') format('woff2'),
		url('../../fonts/Montserrat/Montserrat-ExtraBold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat/Montserrat-Light.woff2') format('woff2'),
		url('../../fonts/Montserrat/Montserrat-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat/Montserrat-Black.woff2') format('woff2'),
		url('../../fonts/Montserrat/Montserrat-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat/Montserrat-Bold.woff2') format('woff2'),
		url('../../fonts/Montserrat/Montserrat-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat/Montserrat-SemiBold.woff2') format('woff2'),
		url('../../fonts/Montserrat/Montserrat-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Mono';
	src: url('../../fonts/RobotoMono/RobotoMono-Medium.woff2') format('woff2'),
		url('../../fonts/RobotoMono/RobotoMono-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Mono';
	src: url('../../fonts/RobotoMono/RobotoMono-Italic.woff2') format('woff2'),
		url('../../fonts/RobotoMono/RobotoMono-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Mono';
	src: url('../../fonts/RobotoMono/RobotoMono-ExtraLight.woff2') format('woff2'),
		url('../../fonts/RobotoMono/RobotoMono-ExtraLight.woff') format('woff');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Mono';
	src: url('../../fonts/RobotoMono/RobotoMono-Regular.woff2') format('woff2'),
		url('../../fonts/RobotoMono/RobotoMono-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Mono';
	src: url('../../fonts/RobotoMono/RobotoMono-Light.woff2') format('woff2'),
		url('../../fonts/RobotoMono/RobotoMono-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Mono';
	src: url('../../fonts/RobotoMono/RobotoMono-Thin.woff2') format('woff2'),
		url('../../fonts/RobotoMono/RobotoMono-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Mono';
	src: url('../../fonts/RobotoMono/RobotoMono-Bold.woff2') format('woff2'),
		url('../../fonts/RobotoMono/RobotoMono-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Mono';
	src: url('../../fonts/RobotoMono/RobotoMono-SemiBold.woff2') format('woff2'),
		url('../../fonts/RobotoMono/RobotoMono-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
